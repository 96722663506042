import React, { useEffect, useState } from "react";
import intel from "../../../src/Assets/Images/intel.png";
import { useAppDispatch } from "../Features/hooks";
import { getapplication, getjob } from "../Features/userDetailSlice";

const Applications = () => {



  const dispatch = useAppDispatch();
  // const { loading, users, error } = useSelector((state:any) => state.userDetail);
  const [allapplication, setApplication] = useState<any[]>([]);





  useEffect(() => {
    console.log('Dispatching getjobsssss...');

    dispatch(getapplication()).then((response) => {
        if (Array.isArray(response?.payload)) {
            
          const applications = response.payload; 
          setApplication(applications);// Assuming response.payload is directly an array of Job objects
          console.log('Jobsssssss', applications);
          
        }
      });
    }, [dispatch]);


  return (
    <>
      <section className="Dashboard">
        <div className="main-Dashboard">
          <div className="Applications-box">
            {allapplication.map((application) => (
              <div className="Applications-card" key={application.id}>
                <div className="intel-part-1">
                  <div className="intel-dp">
                    {/* <img src={application.image} alt="" /> */}
                  </div>
                  <div className="inter-ux">
                    <h3>{application.title}</h3>
                    <p>{application.description}</p>
                  </div>
                </div>
                <div className="intel-part-2">
                  <button>Contact Provider</button>
                  <button className="Application">Track Application</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Applications;
