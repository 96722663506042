import React from "react";

import intel from "../../../src/Assets/Images/intel.png";
const Tracking = ()=>{


return (

    <>
        <section className="Dashboard">
          <div className="main-Dashboard">


            <div className="Tracking-box">

              <div className="Tracking-header">

              <div className="Applications-card no-border">
                <div className="intel-part-1">
                  <div className="intel-dp">
                  <img src={intel} alt="" />
                  </div>
                  <div className="inter-ux">
                  <h3>Intern UX Designer</h3>
                  
                  </div>
                </div>
                <div className="intel-part-2">
                  <button>Contact Provider</button>
                  <button className="Application">See All Applications</button>
                </div>
              </div>
           
              </div>

              <div className="Tracking-body">

                <div className="Tracking-body-box">

                  <div className="Tracking-body-card">

                    <span className="potion"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <circle cx="10" cy="10" r="10" fill="#89A0FF"/>
</svg></span>
<div className="h2-body-box">
  <h2>Application Submitted</h2>
  <p>Submitted on 13 jan 2024</p>
</div>

                  </div>

















                </div>
              </div>

            </div>

            
          </div>
        </section>
   
    </>


)



}


export default Tracking