import React from "react";

import headeri from "../../src/Assets/Images/header-icon.png"
import belli from "../../src/Assets/Images/bell-icon.png"
import navi from "../../src/Assets/Images/nav-id.png"
import Sidetoggle from "./home-section/Sidetoggle";
const Header = ()=>{


return (

    <>

<header>
      
      <nav className="main-nav">
          
          <div className="nav-part-logo">
              <div className="nav-logo">
              
                
               
                    
                      <button type="button" className="no-block"     >
                    <span>  
                      <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                          <path d="M2.15482 15.6868C2.12915 15.7924 2.11983 15.9013 2.1272 16.0097C2.09745 16.8597 2.40982 17.7225 3.06007 18.3727L7.56507 22.882C7.66386 22.9808 7.78114 23.0591 7.91021 23.1126C8.03928 23.1661 8.17762 23.1936 8.31733 23.1936C8.45703 23.1936 8.59537 23.1661 8.72444 23.1126C8.85351 23.0591 8.97079 22.9808 9.06958 22.882C9.16836 22.7832 9.24672 22.6659 9.30019 22.5369C9.35365 22.4078 9.38117 22.2695 9.38117 22.1297C9.38117 21.99 9.35365 21.8517 9.30019 21.7226C9.24672 21.5936 9.16836 21.4763 9.06958 21.3775L4.69207 17H30.8126C31.0944 17 31.3646 16.8881 31.5639 16.6888C31.7631 16.4895 31.8751 16.2193 31.8751 15.9375C31.8751 15.6557 31.7631 15.3855 31.5639 15.1862C31.3646 14.9869 31.0944 14.875 30.8126 14.875H5.05545L9.06958 10.8588C9.25511 10.6569 9.35542 10.3911 9.34955 10.117C9.34368 9.84289 9.23207 9.58166 9.03807 9.38793C8.84406 9.19419 8.58267 9.08296 8.30855 9.07747C8.03443 9.07199 7.7688 9.17268 7.5672 9.3585L3.06007 13.8635C2.56716 14.356 2.24919 14.9964 2.15482 15.6868ZM30.8126 6.375H15.9376C15.6558 6.375 15.3855 6.48694 15.1863 6.6862C14.987 6.88546 14.8751 7.15571 14.8751 7.4375C14.8751 7.71929 14.987 7.98954 15.1863 8.1888C15.3855 8.38806 15.6558 8.5 15.9376 8.5H30.8126C31.0944 8.5 31.3646 8.38806 31.5639 8.1888C31.7631 7.98954 31.8751 7.71929 31.8751 7.4375C31.8751 7.15571 31.7631 6.88546 31.5639 6.6862C31.3646 6.48694 31.0944 6.375 30.8126 6.375ZM30.8126 23.375H15.9376C15.6558 23.375 15.3855 23.4869 15.1863 23.6862C14.987 23.8855 14.8751 24.1557 14.8751 24.4375C14.8751 24.7193 14.987 24.9895 15.1863 25.1888C15.3855 25.3881 15.6558 25.5 15.9376 25.5H30.8126C31.0944 25.5 31.3646 25.3881 31.5639 25.1888C31.7631 24.9895 31.8751 24.7193 31.8751 24.4375C31.8751 24.1557 31.7631 23.8855 31.5639 23.6862C31.3646 23.4869 31.0944 23.375 30.8126 23.375Z" fill="black"/>
                        </svg>
              </span>
                  </button>
                  <Sidetoggle/>
                
       


                <h3>Dashboard</h3>
              </div>
              
              
          </div>

          <div className="nav-box">
              <div className="nav-sec">
                  <input type="search" placeholder="Search something here...."/>
                  <button><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path d="M13.7573 13.0393C14.0021 13.2744 14.253 13.5077 14.4956 13.7496C15.579 14.8301 16.6627 15.9103 17.738 16.9988C17.8517 17.114 17.9468 17.2741 17.9864 17.4296C18.039 17.6359 17.9346 17.8167 17.7461 17.9235C17.5522 18.0333 17.3528 18.0271 17.1706 17.891C17.0925 17.8327 17.0242 17.7605 16.9548 17.6913C15.7106 16.4476 14.4665 15.2038 13.2245 13.9579C13.1616 13.8948 13.1182 13.8123 13.0601 13.7311C11.1594 15.3293 9.02669 15.9681 6.62986 15.6042C4.621 15.2992 2.96942 14.3237 1.70876 12.7308C-0.820168 9.53508 -0.506347 4.97042 2.41336 2.18551C5.34252 -0.608323 9.91898 -0.729596 12.9446 1.85651C16.1558 4.60135 16.7351 9.60001 13.7573 13.0393ZM14.6569 7.84354C14.6529 4.07657 11.6192 1.04137 7.85682 1.03999C4.0987 1.03855 1.05155 4.08214 1.04952 7.83928C1.04742 11.614 4.09339 14.6587 7.86502 14.6519C11.632 14.6453 14.661 11.6088 14.6569 7.84354Z" fill="#8D7F7F"/>
                    </svg></button>
              </div>

              <div className="nav-icon">
                  <img src={headeri} alt=""/>
                  <img src={belli} alt=""/>
              </div>
              <div className="nav-id">
              <img src={navi} alt=""/>
                  <span>
                      <h3>Alex Smith</h3>
                      <p>Admin</p>
                  </span>
              </div>
          </div>


  
        
      </nav>
    
</header>
    </>


)



}


export default Header