import React from "react";


const Contact = ()=>{


return (

    <>
        <section className="Dashboard">
                    <div className="main-Dashboard">
                    

                    <div className="Email-box">

                        <div className="Email-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                                <path d="M40.3332 12.8333L23.8882 23.2833C23.3222 23.6379 22.6678 23.826 21.9998 23.826C21.3319 23.826 20.6775 23.6379 20.1115 23.2833L3.6665 12.8333M7.33317 7.33331H36.6665C38.6916 7.33331 40.3332 8.97494 40.3332 11V33C40.3332 35.025 38.6916 36.6666 36.6665 36.6666H7.33317C5.30813 36.6666 3.6665 35.025 3.6665 33V11C3.6665 8.97494 5.30813 7.33331 7.33317 7.33331Z" stroke="#1A1C1E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                              <h3>Email Address</h3>
                              <h2>example123@gmail.com</h2>
                        </div>

                        <div className="Email-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                                <path d="M25.7581 3.66668C29.4949 4.06042 32.9854 5.71781 35.6524 8.36475C38.3193 11.0117 40.0029 14.4896 40.4248 18.2233M25.7581 11C27.5612 11.3556 29.2158 12.2449 30.5072 13.5526C31.7985 14.8603 32.667 16.5259 32.9998 18.3333M40.3331 31.02V36.52C40.3352 37.0306 40.2306 37.536 40.0261 38.0038C39.8215 38.4716 39.5215 38.8916 39.1453 39.2368C38.769 39.5819 38.3248 39.8447 37.8412 40.0083C37.3575 40.1719 36.845 40.2326 36.3365 40.1867C30.695 39.5737 25.276 37.6459 20.5148 34.5584C16.0851 31.7436 12.3296 27.988 9.5148 23.5584C6.41642 18.7756 4.48824 13.3302 3.88646 7.66335C3.84065 7.15637 3.9009 6.64541 4.06338 6.16299C4.22586 5.68058 4.48701 5.23728 4.8302 4.86132C5.17339 4.48536 5.5911 4.18498 6.05675 3.97931C6.52239 3.77363 7.02575 3.66716 7.5348 3.66668H13.0348C13.9245 3.65792 14.7871 3.97299 15.4617 4.55316C16.1363 5.13332 16.5769 5.939 16.7015 6.82002C16.9336 8.58014 17.3641 10.3083 17.9848 11.9717C18.2315 12.6279 18.2848 13.341 18.1386 14.0266C17.9924 14.7122 17.6527 15.3416 17.1598 15.84L14.8315 18.1683C17.4413 22.7582 21.2416 26.5585 25.8315 29.1683L28.1598 26.84C28.6583 26.3471 29.2876 26.0074 29.9732 25.8612C30.6588 25.715 31.3719 25.7684 32.0281 26.015C33.6915 26.6357 35.4197 27.0662 37.1798 27.2984C38.0704 27.424 38.8837 27.8726 39.4651 28.5588C40.0465 29.245 40.3554 30.1209 40.3331 31.02Z" stroke="#1A1C1E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                              <h3>Phone Number</h3>
                              <h2>+123 87527493</h2>
                        </div>


                        <div className="Email-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                                <g clip-path="url(#clip0_36_173)">
                                  <path d="M22.0001 42.5333C19.3037 42.5333 16.6336 42.0022 14.1424 40.9703C11.6511 39.9384 9.38757 38.426 7.48087 36.5193C5.57417 34.6126 4.0617 32.349 3.0298 29.8578C1.99791 27.3666 1.4668 24.6965 1.4668 22C1.4668 19.3035 1.99791 16.6335 3.0298 14.1422C4.0617 11.651 5.57417 9.38744 7.48087 7.48075C9.38757 5.57405 11.6511 4.06158 14.1424 3.02968C16.6336 1.99779 19.3037 1.46667 22.0001 1.46667C27.4459 1.46667 32.6686 3.63 36.5194 7.48075C40.3701 11.3315 42.5335 16.5542 42.5335 22C42.5335 27.4458 40.3701 32.6685 36.5194 36.5193C32.6686 40.37 27.4459 42.5333 22.0001 42.5333ZM22.0001 42.5333V19.0667C22.0001 17.5107 22.6182 16.0185 23.7184 14.9183C24.8187 13.8181 26.3109 13.2 27.8668 13.2H29.3335M14.6668 24.9333H29.3335" stroke="black" stroke-width="2"/>
                                </g>
                                <defs>
                                  <clipPath id="clip0_36_173">
                                    <rect width="44" height="44" fill="white"/>
                                  </clipPath>
                                </defs>
                              </svg>
                              <h3>Facebook</h3>
                              <h2>Lorem Ipsum</h2>
                        </div>

                        <div className="Email-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none">
                                <path d="M32.775 10.225H32.7955M11.25 1H31.75C37.4109 1 42 5.58908 42 11.25V31.75C42 37.4109 37.4109 42 31.75 42H11.25C5.58908 42 1 37.4109 1 31.75V11.25C1 5.58908 5.58908 1 11.25 1ZM29.7 20.2085C29.953 21.9146 29.6616 23.657 28.8672 25.188C28.0728 26.7189 26.8159 27.9604 25.2753 28.7358C23.7347 29.5113 21.9888 29.7812 20.286 29.5072C18.5831 29.2331 17.01 28.4292 15.7904 27.2096C14.5708 25.99 13.7669 24.4169 13.4928 22.714C13.2188 21.0112 13.4887 19.2653 14.2642 17.7247C15.0396 16.184 16.2811 14.9272 17.812 14.1328C19.343 13.3384 21.0854 13.047 22.7915 13.3C24.5318 13.5581 26.1429 14.369 27.387 15.613C28.631 16.8571 29.4419 18.4682 29.7 20.2085Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                              <h3>Social Media</h3>
                              <h2>@loremipsum12</h2>
                        </div>


                    </div>
                    </div>
        </section>
    </>


)



}


export default Contact